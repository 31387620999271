import React, {useState, useRef} from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import config from "../../config.json";

const AuthForm = (props) => {
   const navigate = useNavigate();
   const passwordInputRef = useRef();
   const retypePasswordInputRef = useRef();

   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState('');

   const submitHandler = (event) => {
      event.preventDefault();

      const enteredPassword = passwordInputRef.current.value;
      const enteredRetypePassword = retypePasswordInputRef.current.value;

      // optional: Add validation
      setIsLoading(true);

      if (enteredPassword !== enteredRetypePassword) {
         setError('Οι κωδικοί δεν ταιριάζουν')
         setIsLoading(false);
      } else {
         axios
            .post(`${config.API_URL}/reset-password/`, {
               "token": props.token,
               "new_password": enteredPassword
            })
            .then(res => {
               // setIsLoading(false);
               navigate('/login');
            })
            .catch(err => {
               setIsLoading(false);
               console.log(err)
               if (err.response.data) {
                  console.log(err.response.data.detail)
                  setError(err.response.data.detail)
               }
            })
      }
   }

   return (
      <div data-theme="default"
         className="lg:h-screen flex flex-col space-y-12 justify-center lg:bg-gradient-to-t from-[#b5caf8] to-[#fdfdff]">
         <div className="lg:flex xl:shadow-lg xl:w-1/2 lg:w-2/3  mx-auto lg:h-4/6 w-full">
            <div
               className="lg:w-7/12 bg-primary lg:rounded-l-lg relative bg-login-bg lg:bg-contain bg-cover bg-no-repeat bg-bottom flex-col justify-between flex">
               <img className="w-3/5 p-12" src="/menuworld-100-white.png" alt=""/>
               <div className="flex flex-col justify-end p-12 h-full space-y-4">
                  <div className="text-4xl font-bold text-white">Επαναφορά κωδικού</div>
                  <div className="text-3xl text-white">Καλωσορίσατε</div>
                  <div className="text-white">Για επιπλέον υποστήριξη παρακαλούμε επικοινωνήστε μαζί μας στο
                     info@wizy.gr ή τηλεφωνικά στο 2102206915
                  </div>
               </div>
            </div>
            <div
               className="lg:w-5/12 bg-white lg:rounded-r-lg flex flex-col space-y-12 p-12 lg:items-center justify-center">
               <div className="text-primary">Εισάγετε τα στοιχεία σας για να συνεχίσετε</div>
               <form onSubmit={submitHandler} className="w-full">
                  <span className="text-error">{error}</span>
                  <div className="mt-8">
                     <input type='password' placeholder="Νέος κωδικός" id='password'
                            className="bg-transparent border-b-2 border-primary w-full focus:outline-none" required
                            ref={passwordInputRef}/>
                  </div>
                  <div className="mt-8">
                     <input type='password' placeholder="Επανάληψη κωδικού" id='retype-password'
                            className="bg-transparent border-b-2 border-primary w-full focus:outline-none" required
                            ref={retypePasswordInputRef}/>
                  </div>
                  <div className="mt-10 flex justify-end">
                     {!isLoading && (<button
                        className="bg-primary text-white font-bold px-8 py-2 rounded-full text-xl">Επαναφορά</button>)}
                     {isLoading && <p>Sending request...</p>}
                  </div>
               </form>
            </div>
         </div>
         <div
            className="flex items-center space-x-2 justify-center bg-gradient-to-t from-[#b5caf8] to-[#fdfdff] pt-12 pb-6 lg:bg-none">
            <div className="text-lg text-white">©</div>
            <div><a href="https://wizy.gr"><img className="mx-auto h-8" src="/wizy_logo_white.png" alt=""/></a></div>
         </div>
      </div>
   );
};

export default AuthForm;