// src/components/Dashboard.js

import React, {useContext, useEffect} from 'react';

import {saveAs} from 'file-saver'
import jwtDecode from "jwt-decode";
import AuthContext from "../store/auth-context";
import config from "../config.json";


const Dashboard = () => {
   const authCtx = useContext(AuthContext)
   const decoded = jwtDecode(authCtx.token)
   let username = decoded.username

   // https://developers.google.com/chart/infographics/docs/qr_codes
   const menu_link = `https://menuworld.gr/${username}`;
   const google_chart_api_url = "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" + menu_link + "&choe=UTF-8";

   const title = 'Πίνακας Ελέγχου'

   const downloadImage = () => {
      saveAs(google_chart_api_url, 'qr_code.jpg');
   }

   useEffect(() => {
      document.title = title + ' | ' + config.SITE_NAME;
   }, [title, config])

   return (
      <div className="px-6 pb-16">
         <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
            <div className="prose w-full max-w-4xl flex-grow">
               <h1>{title}</h1>
               <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="text-center sm:text-left">
                     <h2>Link:</h2>
                     <a className="btn btn-outline btn-primary lowercase no-underline" target="_blank" rel="noopener"
                        title="Το μενού σας" href={menu_link}>{menu_link}</a>
                  </div>
                  <div className="text-center">
                     <img className="block mx-auto max-w-xs" src={google_chart_api_url} alt=""/>
                     <button className="btn" onClick={downloadImage}>ΑΠΟΘΗΚΕΥΣΗ</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Dashboard;