// src/components/Profile.js

import React, {useContext, useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import IconHome from "../components/Icons/IconHome";
import ThemeComponent from "./Theme/ThemeComponent";
import axios from "axios";
import AuthContext from '../store/auth-context';
import config from "../config.json";
import {errorToast, successToast} from "../components/Layout/Toast";


const Profile = () => {
      const authCtx = useContext(AuthContext)
      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [companyName, setCompanyName] = useState('');
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [errors, setErrors] = useState({firstName: "", lastName: "", companyName: "", email: "", password: ""})

      const title = 'Προφίλ'

      const setTheme = (e) => {
         const theme = e.currentTarget.dataset['set_theme']
         const div = document.getElementById('root').querySelector("div");
         div.setAttribute('data-theme', theme);
         localStorage.setItem('theme', theme);
      }

      const firstNameChangeHandler = (event) => {
         console.log('firstName: ' + event.target.value)
         setFirstName(event.target.value);
      };
      const lastNameChangeHandler = (event) => {
         console.log('lastName: ' + event.target.value)
         setLastName(event.target.value);
      };
      const companyNameChangeHandler = (event) => {
         console.log('companyName: ' + event.target.value)
         setCompanyName(event.target.value);
      };
      const emailChangeHandler = (event) => {
         console.log('email: ' + event.target.value)
         setEmail(event.target.value);
      };
      const passwordChangeHandler = (event) => {
         console.log('password: ' + event.target.value)
         setPassword(event.target.value);
      };

      const submitHandler = (event) => {
         event.preventDefault();
         let formIsValid = true;
         setErrors({firstName: "", lastName: "", companyName: "", email: "", password: ""});
         if (!firstName) {
            formIsValid = false;
            setErrors({firstName: "Cannot be empty"});
         }
         if (!lastName) {
            formIsValid = false;
            setErrors({lastName: "Cannot be empty"});
         }
         if (!companyName) {
            formIsValid = false;
            setErrors({companyName: "Cannot be empty"});
         }
         if (!email) {
            formIsValid = false;
            setErrors({email: "Cannot be empty"});
         }

         if (formIsValid) {
            const eData = {
               first_name: firstName,
               last_name: lastName,
               company_name: companyName,
               email: email,
               password: password
            }
            console.log(eData)

            axios
               .put(`${config.API_URL}/users/me`, eData, {
                  withCredentials: true,
                  headers: {
                     Authorization: `Bearer ${authCtx.token}`
                  }
               })
               .then(function (response) {
                  console.log(response);
                  successToast('Οι αλλαγές αποθηκεύτηκαν!')
               })
               .catch(function (error) {
                  console.log(error);
                  errorToast('Οι αλλαγές δεν αποθηκεύτηκαν')
               });
         }
      }

      useEffect(() => {
         document.title = title + ' | ' + config.SITE_NAME;
         const fetchProfile = async () => {
            axios
               .get(`${config.API_URL}/users/me`, {
                  withCredentials: true,
                  headers: {
                     Authorization: `Bearer ${authCtx.token}`
                  }
               })
               .then(response => {
                  console.log(response.data);
                  setFirstName(response.data.first_name);
                  setLastName(response.data.last_name);
                  setCompanyName(response.data.company_name);
                  setEmail(response.data.email);
               });
         }
         fetchProfile();
      }, [title, config, authCtx]);

      return (
         <div className="px-6 pb-16">
            <div className="max-w-full text-sm font-medium breadcrumbs py-6">
               <ul>
                  <li>
                     <Link to="/dashboard" className="hover:text-primary">
                        <IconHome/>
                        Αρχική
                     </Link>
                  </li>
                  <li>
                     {title}
                  </li>
               </ul>
            </div>
            <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
               <div className="prose w-full max-w-2xl flex-grow">
                  <h1>{title}</h1>
                  <form onSubmit={submitHandler}>
                     <div className="px-4 py-5 space-y-8 sm:p-6">
                        <div className="form-control w-full pb-4">
                           <label className="label mb-1">
                              <span className="label-text">Χρώματα πίνακα ελέγχου</span>
                           </label>
                           <div
                              className="rounded-box grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                              <ThemeComponent theme={'default'} onClick={setTheme}/>
                              <ThemeComponent theme={'light'} onClick={setTheme}/>
                              <ThemeComponent theme={'dark'} onClick={setTheme}/>
                              <ThemeComponent theme={'cupcake'} onClick={setTheme}/>
                              <ThemeComponent theme={'bumblebee'} onClick={setTheme}/>
                              <ThemeComponent theme={'emerald'} onClick={setTheme}/>
                              <ThemeComponent theme={'corporate'} onClick={setTheme}/>
                              <ThemeComponent theme={'synthwave'} onClick={setTheme}/>
                              <ThemeComponent theme={'retro'} onClick={setTheme}/>
                              <ThemeComponent theme={'cyberpunk'} onClick={setTheme}/>
                              <ThemeComponent theme={'valentine'} onClick={setTheme}/>
                              <ThemeComponent theme={'halloween'} onClick={setTheme}/>
                              <ThemeComponent theme={'garden'} onClick={setTheme}/>
                              <ThemeComponent theme={'forest'} onClick={setTheme}/>
                              <ThemeComponent theme={'aqua'} onClick={setTheme}/>
                              <ThemeComponent theme={'lofi'} onClick={setTheme}/>
                              <ThemeComponent theme={'pastel'} onClick={setTheme}/>
                              <ThemeComponent theme={'fantasy'} onClick={setTheme}/>
                              <ThemeComponent theme={'wireframe'} onClick={setTheme}/>
                              <ThemeComponent theme={'black'} onClick={setTheme}/>
                              <ThemeComponent theme={'luxury'} onClick={setTheme}/>
                              <ThemeComponent theme={'dracula'} onClick={setTheme}/>
                              <ThemeComponent theme={'cmyk'} onClick={setTheme}/>
                              <ThemeComponent theme={'autumn'} onClick={setTheme}/>
                              <ThemeComponent theme={'business'} onClick={setTheme}/>
                              <ThemeComponent theme={'acid'} onClick={setTheme}/>
                              <ThemeComponent theme={'lemonade'} onClick={setTheme}/>
                              <ThemeComponent theme={'night'} onClick={setTheme}/>
                              <ThemeComponent theme={'coffee'} onClick={setTheme}/>
                              <ThemeComponent theme={'winter'} onClick={setTheme}/>
                           </div>
                        </div>

                        <div className="flex lg:flex-row flex-col lg:items-center space-y-1 lg:space-y-0">
                           <div className="flex items-center lg:w-2/5 justify-between">
                              <div className="label-text">Επωνυμία επιχείρησης</div>                              
                           </div>
                           <div className="lg:w-3/5 w-full relative">
                              <input type="text" onChange={companyNameChangeHandler}
                                     value={companyName == null ? '' : companyName}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                              <div className="absolute label pt-1 right-0 text-error text-xs">{errors.companyName}</div>
                           </div>
                        </div>

                        <div className="flex lg:flex-row flex-col lg:items-center space-y-1 lg:space-y-0">
                           <div className="flex items-center lg:w-2/5 justify-between">
                              <div className="label-text">Όνομα</div>                              
                           </div>
                           <div className="lg:w-3/5 w-full relative">
                              <input type="text" onChange={firstNameChangeHandler}
                                     value={firstName == null ? '' : firstName}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                               <div className="absolute label pt-1 right-0 text-error text-xs">{errors.firstName}</div>       
                           </div>
                        </div>

                        <div className="flex lg:flex-row flex-col lg:items-center space-y-1 lg:space-y-0">
                           <div className="flex items-center lg:w-2/5 justify-between">
                              <div className="label-text">Επίθετο</div>                              
                           </div>
                           <div className="lg:w-3/5 w-full relative">
                              <input type="text" onChange={lastNameChangeHandler} value={lastName == null ? '' : lastName}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                              <div className="absolute label pt-1 right-0 text-error text-xs">{errors.lastName}</div>       
                           </div>
                        </div>

                        <div className="flex lg:flex-row flex-col lg:items-center space-y-1 lg:space-y-0">
                           <div className="flex items-center lg:w-2/5 justify-between">
                              <div className="label-text">Email (απαιτείται)</div>                              
                           </div>
                           <div className="lg:w-3/5 w-full relative">
                              <input type="text" onChange={emailChangeHandler} value={email == null ? '' : email}
                                     placeholder=""
                                     className="input input-bordered input-primary w-full"/>
                              <div className="absolute label pt-1 right-0 text-error text-xs">{errors.email}</div>       
                           </div>
                           
                        </div>
                        <div className="flex lg:flex-row flex-col lg:items-center space-y-1 lg:space-y-0">
                           <div className="flex items-center lg:w-2/5 justify-between">
                              <div className="label-text">Νέο συνθηματικό</div>
                              <div
                                 className="label text-error text-xs lg:hidden text-right inline-block">{errors.password}</div>
                           </div>
                           <div className="lg:w-3/5 w-full relative">
                              <input type="text" onChange={passwordChangeHandler} value={password == null ? '' : password}
                                     placeholder="" className="input input-bordered input-primary w-full"/>
                              <div className="absolute label pt-1 right-0 text-error text-xs">{errors.password}</div>       
                           </div>
                        </div>


                        <div className="py-3 bg-base-200 bg-opacity-20 text-right sm:px-6 max-w-2xl">
                           <button className="btn btn-primary">ΑΠΟΘΗΚΕΥΣΗ</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      );
   }
;

export default Profile;