// src/components/NavBar.js

import React, {useRef, useState, useEffect} from 'react';
import {Link} from 'react-router-dom'

import NavBarSearch from "./NavBarSearch";
import NavBarMenu from "./NavBarMenu";
import NavBarThemeMenu from "./NavBarThemeMenu";
import IconHamburger from "../../Icons/IconHamburger";
import config from "../../../config.json";


const NavBar = (props) => {

   return (
      <div
         className='sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-90 backdrop-blur transition-all duration-100 bg-base-100 text-base-content'>
         <nav className="navbar w-full">
            <div className="flex flex-1 md:gap-1 lg:gap-2">
               <label htmlFor="drawer" className="btn btn-square btn-ghost drawer-button lg:hidden">
                  <IconHamburger/>
               </label>
               <div className="flex items-center gap-2 lg:hidden">
                  <Link to="/dashboard" className="flex-0 btn btn-ghost px-2 ">
                     <div className="flex flex-col items-end space-x-1">
                     <svg className="fill-current text-primary w-16" version="1.1" viewBox="0 0 256.52 65.476" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(15.168 -43.374)">
                            <g transform="translate(183.64 -117.86)">
                                <g strokeWidth="2.0132">
                                    <path
                                        d="m-45.005 194.86q0 4.6094-2.6602 6.8763-2.5744 2.1913-5.7495 2.1913h-20.338q0 2.1158 2.8319 3.5515 2.8319 1.4357 5.6637 1.4357 4.9772 0 7.8091-0.90676l0.94395-0.30225q2.0595-0.8312 3.4326-0.8312 2.746 0 4.7198 3.4004 1.1156 2.0402 1.1156 3.4759 0 6.7251-18.278 6.7251-6.3502 0-11.327-1.8891-4.8914-1.9646-7.6374-5.1383-5.4063-6.1206-5.4063-13.677 0-9.521 6.9509-15.188 7.0367-5.7428 17.506-5.7428 11.928 0 17.506 7.4052 2.9177 3.9293 2.9177 8.6142zm-18.107 2.1913q3.2609 0 3.2609-2.418 0-1.738-1.5446-2.7958-1.4588-1.0579-4.2907-1.0579-2.746 0-5.4921 2.0402-2.746 1.9646-2.746 4.2316z" />
                                    <path
                                        d="m-14.283 179.67q8.5814 0 14.245 5.8184 5.7495 5.7428 5.7495 14.055v13.299q0 1.738-0.17163 2.6447-0.085814 0.8312-0.68651 1.8891-1.2014 2.0402-6.6935 2.0402-6.0928 0-7.0367-2.7958-0.51488-1.2846-0.51488-3.8537v-13.299q0-3.0981-2.0595-4.836-1.9737-1.738-5.4063-1.738-3.3467 0-5.4921 1.8135-2.0595 1.8135-2.0595 4.7605v13.375q0 1.738-0.17163 2.6447-0.08581 0.8312-0.77232 1.8891-1.1156 2.0402-6.6077 2.0402-5.4063 0-6.6077-2.0402-0.6007-1.1334-0.77233-1.9646-0.08582-0.90677-0.08582-2.6447v-26.749q0-1.6624 0.08582-2.4936 0.17163-0.90676 0.85814-1.9646 1.2014-1.8891 6.6077-1.8891 5.2347 0 6.436 1.738 0.85814 1.2846 0.85814 3.0981 0.34326-0.52894 1.5447-1.5113 1.2014-0.98233 2.317-1.5868 2.9177-1.738 6.436-1.738z" />
                                    <path
                                        d="m32.657 219.35q-8.6672 0-14.417-5.7428-5.6637-5.8184-5.6637-14.13v-13.224q0-1.8135 0.08582-2.6447 0.17163-0.83119 0.77232-1.9646 1.2014-2.0402 6.6935-2.0402 6.007 0 7.0367 2.8714 0.51488 1.209 0.51488 3.8537v13.224q0 3.0981 1.9737 4.8361 2.0595 1.738 5.4063 1.738 3.4326 0 5.4921-1.8135 2.1453-1.8135 2.1453-4.7605v-13.375q0-1.738 0.08582-2.5692 0.17163-0.90676 0.85814-2.0402 1.1156-1.9646 6.6077-1.9646 5.4063 0 6.6077 2.0402 0.6007 1.1335 0.68651 2.0402 0.17163 0.83119 0.17163 2.5692v26.749q0 1.6624-0.17163 2.5692-0.08582 0.8312-0.77233 1.8891-1.2872 1.9646-6.6077 1.9646-5.2347 0-6.436-1.8135-0.85814-1.2846-0.85814-3.0981-0.34326 0.52895-1.5447 1.5113-1.2014 0.98232-2.317 1.6624-2.9177 1.6624-6.3502 1.6624z" />
                                </g>
                                <path
                                    d="m-119.09 174.59 21.707 13.737c1.1546 0.73068 2.0842 2.4189 2.0842 3.7855v32.175c0 1.3664-0.95918 1.8782-2.1138 1.1475l-21.707-13.737c-0.38663-0.24468-2.0248-1.3387-2.0248-1.3387l-0.0298-36.959s1.513 0.82835 2.0842 1.1898zm-4.1684 0-21.707 13.737s-2.0843 1.1138-2.0843 1.2033v37.178l2.0843-1.2735 21.707-13.737c0.49517-0.31335 2.114-1.3388 2.114-1.3388s-0.0298-36.956-0.0301-36.957c0 0-1.528 0.83547-2.0842 1.1875zm-47.583 0 21.707 13.737s2.0842 1.155 2.0842 1.2033v37.178l-2.0842-1.2735-21.707-13.737c-0.38663-0.24468-2.0875-1.3387-2.0875-1.3387l3e-3 -36.959s1.513 0.82835 2.0842 1.1898zm-4.1684 0-21.707 13.737c-1.1547 0.73079-2.0843 2.4189-2.0843 3.7855v32.175c0 1.3664 0.92955 1.8782 2.0843 1.1475l21.707-13.737c0.49517-0.31335 2.0809-1.332 2.0809-1.332l3e-3 -36.964s-1.528 0.83547-2.0842 1.1875z"
                                    strokeLinecap="round" strokeWidth="2.7655"/>
                            </g>
                        </g>
                    </svg>
                    <svg className="fill-current text-secondary-content w-16"  version="1.1" viewBox="0 0 273.45 63.294" xmlns="http://www.w3.org/2000/svg">
                     <g transform="translate(-141.58 -26.499)">
                         <g transform="translate(79.05 -134.74)">
                             <path
                                 d="m86.325 223.34-21.707-13.737c-1.1546-0.73068-2.0842-2.4189-2.0842-3.7855v-32.175c0-1.3664 0.95918-1.8782 2.1138-1.1475l21.707 13.737c0.38663 0.24468 2.0248 1.3387 2.0248 1.3387l0.0298 36.959s-1.513-0.82835-2.0842-1.1898zm4.1684 0 21.707-13.737s2.0843-1.1138 2.0843-1.2033v-37.178l-2.0843 1.2735-21.707 13.737c-0.49517 0.31335-2.114 1.3388-2.114 1.3388s0.0298 36.956 0.0301 36.957c0 0 1.528-0.83547 2.0842-1.1875zm47.583 0-21.707-13.737s-2.0842-1.155-2.0842-1.2033v-37.178l2.0842 1.2735 21.707 13.737c0.38663 0.24468 2.0875 1.3387 2.0875 1.3387l-3e-3 36.959s-1.513-0.82835-2.0842-1.1898zm4.1684 0 21.707-13.737c1.1547-0.73079 2.0843-2.4189 2.0843-3.7855v-32.175c0-1.3664-0.92955-1.8782-2.0843-1.1475l-21.707 13.737c-0.49517 0.31335-2.0809 1.332-2.0809 1.332l-3e-3 36.964s1.528-0.83547 2.0842-1.1875z"
                                 strokeLinecap="round" strokeWidth="2.7655"/>
                             <g transform="matrix(1.0657 0 0 .93838 7.2553e-6 0)" strokeWidth="2.0132" aria-label="orld">
                                 <path
                                     d="m160.91 213.06q0-9.6631 6.7642-15.944 6.7642-6.281 15.461-6.281 8.6968 0 15.38 6.281 6.6836 6.2005 6.6836 15.864 0 6.6836-3.4626 11.918-3.4626 5.1536-8.4552 7.6499-4.9121 2.4158-10.227 2.4158-5.3147 0-10.307-2.5768-4.9926-2.6574-8.4552-7.7305-3.3821-5.1536-3.3821-11.596zm16.83 5.7978q2.5768 1.9326 5.2342 1.9326t5.3147-2.0132q2.6573-2.0131 2.6573-5.9589t-2.4963-5.8784q-2.4963-1.9326-5.3952-1.9326-2.8989 0-5.3952 2.0131-2.4963 2.0131-2.4963 5.9589 0 3.8652 2.5768 5.8784z" />
                                 <path
                                     d="m239.26 192.28q1.53 0.48315 2.4963 1.691 1.0468 1.1274 1.0468 3.7042t-1.53 5.6368q-1.53 3.06-4.4289 3.06-1.4495 0-2.8184-0.6442-1.2884-0.64421-3.4626-0.64421t-4.1068 1.53q-1.8521 1.53-1.8521 3.7042v16.91q0 1.8521-0.16105 2.8184-0.0805 0.88578-0.72473 2.0937-1.2079 2.0937-6.281 2.0937-3.8652 0-5.5563-1.3689-1.2884-1.1274-1.4495-3.4626 0-0.80526 0-2.3352v-28.265q0-1.8521 0.0805-2.7379 0.16105-0.9663 0.72473-2.0937 1.1274-2.1742 6.281-2.1742 4.8315 0 6.0394 1.8521 0.88579 1.2884 0.88579 2.6574 0.40263-0.56368 1.1274-1.3689 0.80525-0.80526 3.3016-2.1742t4.3484-1.3689q1.9326 0 3.221 0.24158 1.2884 0.16105 2.8184 0.64421z" />
                                 <path
                                     d="m247.23 178.91q0-1.8521 0.0805-2.7379 0.16105-0.96631 0.72473-2.1742 1.1274-2.1742 6.281-2.1742 4.9121 0 6.12 2.1742 0.64421 1.2079 0.72473 2.1742 0.16105 0.9663 0.16105 2.8184v37.283q0 2.8989 0.48316 3.7042 0.48315 0.72473 2.0937 0.72473 1.691 0 2.2547 0.16105 0.56368 0.0805 1.3689 0.64421 1.6105 1.0468 1.6105 5.7173 0 5.0731-1.6105 6.2005-2.0937 1.4495-9.9852 0.48316-6.2005-0.80526-8.0526-4.1068-2.2547-3.9458-2.2547-12.562z" />
                                 <path
                                     d="m301.99 174.16q0.6442-1.2079 2.1742-1.691 1.53-0.48315 4.0263-0.48315 2.5768 0 4.1068 0.48315 1.53 0.48316 2.0937 1.691 0.6442 1.2079 0.72473 2.1742 0.16105 0.88578 0.16105 2.7379v48.074q0 1.8521-0.16105 2.8184-0.0805 0.88578-0.72473 2.0131-1.2079 2.1742-6.8447 2.1742-5.5563 0-6.2005-3.9458-3.3821 3.8652-9.4215 3.8652-7.2473 0-13.206-6.281-5.9589-6.3615-5.9589-14.736 0-8.4552 5.9589-14.817 6.0394-6.3615 13.206-6.3615 5.9589 0 9.2605 4.0263v-16.91q0-1.8521 0.0805-2.7379 0.16105-0.96631 0.72473-2.0937zm-13.045 43.725q2.0937 2.1742 5.0731 2.1742t4.9121-2.1742q2.0131-2.1742 2.0131-4.751 0-2.6573-1.9326-4.9121-1.8521-2.2547-5.0731-2.2547t-5.1536 2.2547q-1.9326 2.1742-1.9326 4.8315t2.0937 4.8315z" />
                             </g>
                         </g>
                     </g>
                 </svg>
                  </div>
                  </Link>
                  <div className="font-mono text-xs text-opacity-50">{config.VERSION}</div>
               </div>
               <NavBarSearch/>
            </div>
            <div className="flex-0">
               <NavBarThemeMenu/>
               <div className="font-medium pr-2">{props.name}</div>
               <NavBarMenu
                  name={props.name}
                  email={props.email}
                  splitName={props.splitName}
               />
            </div>
         </nav>
      </div>
   );
};

export default NavBar;